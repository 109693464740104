@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "GT Super Ds Trial Bd";
  src: url("./assets/fonts/GTSuper/GTSuperDsTrial-Bd.eot");
  src: local("./assets/fonts/GTSuper/GT Super Ds Trial Bd"),
    local("./assets/fonts/GTSuper/GTSuperDsTrial-Bd"),
    url("./assets/fonts/GTSuper/GTSuperDsTrial-Bd.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/GTSuper/GTSuperDsTrial-Bd.woff2") format("woff2"),
    url("./assets/fonts/GTSuper/GTSuperDsTrial-Bd.woff") format("woff"),
    url("./assets/fonts/GTSuper/GTSuperDsTrial-Bd.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT Super Ds Trial Su";
  src: url("./assets/fonts/GTSuper/GTSuperDsTrial-Su.eot");
  src: local("./assets/fonts/GTSuper/GT Super Ds Trial Su"),
    local("./assets/fonts/GTSuper/GTSuperDsTrial-Su"),
    url("./assets/fonts/GTSuper/GTSuperDsTrial-Su.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/GTSuper/GTSuperDsTrial-Su.woff2") format("woff2"),
    url("./assets/fonts/GTSuper/GTSuperDsTrial-Su.woff") format("woff"),
    url("./assets/fonts/GTSuper/GTSuperDsTrial-Su.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT Super Ds Medium";
  src: url("./assets/fonts/GTSuper/GTSuperDsMedium.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT Walsheim Pro Bold";
  src: url("./assets/fonts/GTWalsheim/GTWalsheimPro-Bold.eot");
  src: local("./assets/fonts/GTWalsheim/GT Walsheim Pro Bold"),
    local("./assets/fonts/GTWalsheim/GTWalsheimPro-Bold"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Bold.woff2") format("woff2"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Bold.woff") format("woff"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT Walsheim Pro Regular";
  src: url("./assets/fonts/GTWalsheim/GTWalsheimPro-Regular.eot");
  src: local("./assets/fonts/GTWalsheim/GT Walsheim Pro Regular"),
    local("./assets/fonts/GTWalsheim/GTWalsheimPro-Regular"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Regular.woff2") format("woff2"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Regular.woff") format("woff"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT Walsheim Pro Medium";
  src: url("./assets/fonts/GTWalsheim/GTWalsheimPro-Medium.eot");
  src: local("GT Walsheim Pro Medium"), local("GTWalsheimPro-Medium"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Medium.woff2") format("woff2"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Medium.woff") format("woff"),
    url("./assets/fonts/GTWalsheim/GTWalsheimPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

:root {
  --gt-w-regular: "GT Walsheim Pro Regular";
  --gt-w-medium: "GT Walsheim Pro Medium";
  --gt-w-bold: "GT Walsheim Pro Bold";
  --gt-su: "GT Super Ds Trial Su";
  --gt-su-md: "GT Super Ds Medium";
  --gt-su-bd: "GT Super Ds Trial Bd";
}

:where(.css-dev-only-do-not-override-w8mnev).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #efa92f !important;
  font-family: var(--gt-w-regular);
  font-size: 14px;
}

:where(.css-dev-only-do-not-override-1r287do).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #efa92f;
  font-family: var(--gt-w-medium);
  text-shadow: 0 0 0.25px currentcolor;
}

:where(.css-dev-only-do-not-override-1r287do).ant-tabs
  .ant-tabs-tab
  .ant-tabs-tab-btn {
  font-family: var(--gt-w-regular);
  color: #aaaaaa;
}

:where(.css-dev-only-do-not-override-w8mnev).ant-tabs
  .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #919294 !important;
  font-family: var(--gt-w-regular);
  font-size: 14px;
}

:where(.css-dev-only-do-not-override-1r287do).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #efa92f;
  pointer-events: none;
}

.ant-tabs-nav {
  padding-left: 24px;
}

.otp-container input,
.otp-container input:not(:focus) {
  width: 64px !important;
  height: 64px !important;
  background: #f6f6f6;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 1px 2px rgba(36, 38, 40, 0.05);
  border-radius: 8px;
  font-size: 48px;
  line-height: 60px;
  font-family: var(--gt-w-medium);
}

@media screen and (max-width: 480px) {
  .otp-container input,
  .otp-container input:not(:focus) {
    width: 48px !important;
    height: 48px !important;
    border-radius: 4px;
    font-size: 24px;
    line-height: 60px;
  }
}

.otp-container input:active,
.otp-container input:focus,
.otp-container input[type="text" i]:focus-visible {
  background: #ffffff !important;
  border: 2px solid #2a2a2a;
  box-shadow: 0px 1px 2px rgba(36, 38, 40, 0.05);
  border-radius: 8px;
  text-align: center;
  color: #2a2a2a;
}
:focus-visible {
  outline: #2a2a2a auto 2px;
}
.otp-container input:valid {
  color: #2a2a2a;
}
.otp-container input:not(:placeholder-shown) {
  border: 2px solid #f6f6f6;
  background: #f6f6f6 !important;
}

.otp-timer-countdown {
  border-radius: 20px;
  background: rgba(240, 24, 24, 0.1);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  color: #f74f4f;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ig-style {
  background: radial-gradient(
      89.12% 77.38% at 31.35% 101.03%,
      #fdcf2c 0%,
      #ff6930 48.44%,
      #fc403c 73.44%,
      rgba(254, 59, 54, 0) 100%
    ),
    radial-gradient(
      207.29% 118.5% at 84.5% 113.5%,
      #ed3895 24.39%,
      #f80261 43.67%,
      #ed00c0 68.85%,
      #c500e9 77.68%,
      rgba(104, 24, 232, 0.95) 89.32%
    );
}

/** calendar styles **/
.react-calendar {
  border: 1px solid #eeeeee !important;
  border-radius: 24px;
  font-family: "GT Walsheim Pro Regular" !important;
  width: 100%;
  max-width: 340px;
}

.react-calendar__tile--active {
  background-color: #efa92f !important;
}

.react-calendar__tile--now {
  background-color: #2a2a2a !important;
  color: #efa92f !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__tile:enabled:hover {
  background-color: #fafafa !important;
}
